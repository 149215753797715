import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import type { AppProps } from 'next/app';
import { Provider } from 'urql';
import { Analytics } from '@vercel/analytics/react';
import { init } from '@amplitude/analytics-browser';

import { client } from '../client/graphql/client';
import Layout from '../client/components/Layout';
import ModalProvider from '../client/components/ModalProvider/ModalProvider';

import 'react-notion-x/src/styles.css';
import '../styles/globals.css';

if (typeof window !== 'undefined') {
  init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '', {
    defaultTracking: true,
    serverUrl: '/api/amp',
  });
}

function CustomApp({ Component, pageProps, router }: AppProps) {
  if (router.route.startsWith('/frame/')) return <Component {...pageProps} />;

  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/fonts/EBGaramond-Regular.ttf"
          as="font"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/EBGaramond-SemiBold.ttf"
          as="font"
          crossOrigin="anonymous"
        />
        <link rel="preload" href="/fonts/EBGaramond-Bold.ttf" as="font" crossOrigin="anonymous" />
        <script
          async
          defer
          data-website-id="2c173362-0df7-4ec5-957b-3296b65450fb"
          data-host-url="https://analytics.balla.dev/"
          src="/patha.js"
        ></script>
        <link rel="apple-touch-icon" sizes="200x200" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Provider value={client}>
        <ModalProvider>
          <Layout>
            <Component {...pageProps} />
            <Toaster position="bottom-center" />
          </Layout>
        </ModalProvider>
      </Provider>
      <Analytics />
    </>
  );
}

export default CustomApp;

import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type ActiveModalType = 'ADD_NEWSLETTER' | 'BILLING' | 'EMBED_CODE' | 'LIMIT_EXCEEDED' | null;

const ModalContext = createContext<{
  activeModal: ActiveModalType;
  setActiveModal: Dispatch<SetStateAction<ActiveModalType>>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ activeModal: null, setActiveModal: () => {} });

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeModal, setActiveModal] = useState<ActiveModalType>(null);
  return (
    <ModalContext.Provider value={{ activeModal: activeModal, setActiveModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const { activeModal, setActiveModal } = useContext(ModalContext);

  return {
    activeModal,
    openModal: (modal: ActiveModalType) => {
      setActiveModal(modal);
    },
    closeModal: () => {
      setActiveModal(null);
    },
  };
};

export default ModalProvider;

import { Menu, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useGetCurrentUserQuery } from '../../graphql/getCurrentUser.generated';
import Logo from '../Icons/Logo';
import { useModal } from '../ModalProvider/ModalProvider';

function Navbar() {
  const [{ data }] = useGetCurrentUserQuery();
  const isAuthenticated = !!data?.currentUser;
  const { pathname } = useRouter();
  const { openModal } = useModal();

  return (
    <div className="flex justify-between py-6 px-12">
      <div className="flex items-center">
        <Logo />
        <Link href={isAuthenticated ? `/dashboard` : `/`}>
          <a className="font-bold ml-4">stackblocks</a>
        </Link>
      </div>

      {isAuthenticated ? (
        <>
          {pathname.startsWith('/dashboard') ? (
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="bg-gray-100 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Open user menu</span>
                  <UserCircleIcon className="text-gray-800 h-8 w-8 rounded-full" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block px-4 py-2 text-sm text-gray-700`}
                        onClick={() => {
                          openModal('BILLING');
                        }}
                      >
                        💵 Billing
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/logout"
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } block px-4 py-2 text-sm text-gray-700`}
                      >
                        👋 Log out
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <Link href="/dashboard">
              <a className="text-orange-700 text-xs bg-orange-200 hover:bg-orange-300 py-1 px-4 rounded">
                Go to App
              </a>
            </Link>
          )}
        </>
      ) : (
        <div className="gap-x-8 hidden sm:flex items-center">
          <Link href="/blog">
            <a className="text-sm hover:text-gray-700">Blog</a>
          </Link>
          <Link href="/#features">
            <a className="text-sm hover:text-gray-700">Features</a>
          </Link>
          <Link href="/pricing">
            <a className="text-sm hover:text-gray-700">Pricing</a>
          </Link>
          <Link href="/login">
            <a className="text-orange-700 text-xs bg-orange-200 hover:bg-orange-300 py-1 px-4 rounded">
              Log In
            </a>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;

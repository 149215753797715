import { useRouter } from 'next/router';
import Logo from '../Icons/Logo';

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  main: [
    { name: 'Guides', href: '/guides' },
    { name: 'Pricing', href: '/pricing' },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/StackblocksApp',
      icon: (props: React.SVGAttributes<HTMLOrSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  const { pathname } = useRouter();

  if (pathname.startsWith('/dashboard')) return null;

  return (
    <footer className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="px-5 pt-6 mx-auto flex items-center justify-center sm:flex-row flex-col">
          <div className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <Logo />
          </div>
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
            © {new Date().getFullYear()} Stackblocks —
            <a
              href="https://twitter.com/aravindballa"
              rel="noopener noreferrer"
              className="text-twitter-blue ml-1"
              target="_blank"
            >
              @aravindballa
            </a>
          </p>
        </div>
        {/* <div className="mt-8 flex justify-center space-x-6">
          <a href="https://graphcdn.io/?ref=powered-by">
            <img
              className="w-24 h-auto"
              src="https://graphcdn.io/badge.svg"
              alt="Powered by GraphCDN, the GraphQL CDN"
            />
          </a>
        </div> */}
      </div>
    </footer>
  );
}
